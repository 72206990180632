import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { USERTYPE } from '@shared/enums';
import { AuthUtils } from '../auth.utils';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/auth.state';

@Injectable({
  providedIn: 'root',
})
export class LandingPageGuard implements CanActivate {
  USERTYPE = USERTYPE;

  constructor(
    private router: Router,
    private store: Store,
  ) {}

  canActivate(): boolean {
    const userType = this.store.selectSnapshot(AuthState.getUser)?.userType;

    if (!userType || userType === USERTYPE.Client) {
      return true;
    }

    if (userType === USERTYPE.Internal || userType === USERTYPE.Tenant) {
      this.router.navigate([AuthUtils.getUserDashboardLink(userType)]);
      return false;
    }

    return true;
  }
}
