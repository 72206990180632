import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AlertComponent } from './shared/components/alert/alert.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { Store } from '@ngxs/store';
import { LoaderSelectors } from './core/store/loader.selectors';
import { CommonModule } from '@angular/common';
import { loadCountries } from './shared/store/countries/countries.actions';
import { CountriesState } from './shared/store/countries/countries.state';
import { SvgIconService } from './shared/services/svg.icon.service';
import { SVG_ICONS } from './shared/components/svg-icons/svg.icon';
import { GetQuestionTypes, loadUserType, UserTypeState } from '@shared/store';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    // Modules
    RouterOutlet,
    CommonModule,

    // Component
    AlertComponent,
    LoaderComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'UVA';

  readonly #store = inject(Store);
  svgIconService = inject(SvgIconService);

  loaderState = this.#store.selectSignal(LoaderSelectors.getLoadingState);

  ngOnInit(): void {
    this.svgIconService.registerIcons(SVG_ICONS);

    const countryList = this.#store.selectSnapshot(CountriesState.getCountries);
    if (!countryList.length) this.#store.dispatch(new loadCountries());

    const userTypeList = this.#store.selectSnapshot(UserTypeState.getUserTypes);
    if (!userTypeList.length) this.#store.dispatch(new loadUserType());

    // Get the question types
    this.#store.dispatch(new GetQuestionTypes());
  }
}
