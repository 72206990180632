import { inject, Injectable } from '@angular/core';
import { CanMatch, Router } from '@angular/router';
import { AuthState } from '../store/auth.state';
import { Store } from '@ngxs/store';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanMatch {
  authService = inject(AuthService);
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canMatch(): boolean {
    const isAuthenticated = this.store.selectSnapshot(AuthState.getIsAuthenticated);
    if (isAuthenticated) {
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
