import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '../../auth/store/auth.state';
import { AuthUtils } from '../../auth/auth.utils';

@Component({
  selector: 'app-unauthorized',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.scss',
})
export class UnauthorizedComponent {
  private router = inject(Router);
  private store = inject(Store);

  user = this.store.selectSnapshot(AuthState.getUser);

  goHome() {
    this.router.navigate([AuthUtils.getUserDashboardLink(this.user?.userType as number)]);
  }
}
