import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { MAT_DATE_LOCALE } from '@angular/material/core';

import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideStore } from '@ngxs/store';
import { AuthState } from './auth/store/auth.state';
import { withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AlertState } from './shared/store/alert/alert.state';
import { QuestionState } from './shared/store/question/question.state';
import { LoaderState } from './core/store/loader.state';
import { UVATeamState } from './portal/uva-team/store/uva-team.state';
import { CountriesState } from './shared/store/countries/countries.state';
import { authInterceptor } from './auth/interceptors/auth.interceptor';
import { UserTypeState } from '@shared/store';
import { FormMappingState } from './portal/uva-team/store/form-mapping/form-mapping.state';
import { ApplicantsState } from './portal/embassy/store/applicants/applicants.state';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { PendingApplicationState } from './portal/customer/store/visa-application/pending-application.state';
import { ResolvedApplicationState } from './portal/customer/store/visa-application/resolved-application.state';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideMomentDateAdapter(MY_FORMATS),
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en',
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' },
    },
    provideHttpClient(withInterceptors([authInterceptor])),
    provideStore(
      [
        AuthState,
        AlertState,
        LoaderState,
        UVATeamState,
        QuestionState,
        CountriesState,
        UserTypeState,
        FormMappingState,
        ApplicantsState,
        PendingApplicationState,
        ResolvedApplicationState,
      ],
      withNgxsStoragePlugin({ keys: ['auth.token', 'countries.countries', 'auth.user'] }),
      withNgxsReduxDevtoolsPlugin(),
    ),
    provideOAuthClient(),
  ],
};
