import { Routes } from '@angular/router';
import { NoAuthGuard } from './auth/guards/no-auth.guard';
import { RoleGuard } from './auth/guards/role.guard';
import { UnauthorizedComponent } from './public/unauthorized/unauthorized.component';
import { USERTYPE } from '@shared/enums';
import { LandingPageGuard } from './auth/guards/landing-page.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./public/landing-page/landing-page.component').then((p) => p.LandingPageComponent),
    canActivate: [LandingPageGuard],
  },
  {
    path: 'portal',
    children: [
      {
        path: 'uva-team',
        loadChildren: () => import('./portal/uva-team/uva-team.module').then((m) => m.UvaTeamModule),
        canActivate: [RoleGuard],
        data: { userType: USERTYPE.Internal },
      },
      {
        path: 'embassy',
        loadChildren: () => import('./portal/embassy/embassy.module').then((m) => m.EmbassyModule),
        canActivate: [RoleGuard],
        data: { userType: USERTYPE.Tenant },
      },
      {
        path: 'customer',
        loadChildren: () => import('./portal/customer/customer.module').then((m) => m.CustomerModule),
        canActivate: [RoleGuard],
        data: { userType: USERTYPE.Client },
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canMatch: [NoAuthGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
