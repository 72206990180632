import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/auth.state';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.checkUserType(route.data['userType']);
  }

  private checkUserType(userType: number): boolean {
    const user = this.store.selectSnapshot(AuthState.getUser)?.userType;
    if (user === userType) {
      return true;
    }
    this.router.navigate(['/unauthorized']);
    return false;
  }
}
