import { HttpInterceptorFn } from '@angular/common/http';
import { AuthUtils } from '../auth.utils';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const token = authService.accessToken;
  const isGoogleDiscoveryRequest = req.url.includes('https://accounts.google.com/.well-known/openid-configuration');

  if (token && !AuthUtils.isTokenExpired(token) && !isGoogleDiscoveryRequest) {
    const cloned = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token),
    });
    return next(cloned);
  } else {
    return next(req);
  }
};
